export const pages = [
  {
    title: 'menu.home',
    href: '/',
  },
  {
    title: 'menu.about',
    href: '/quem-somos',
  },
  {
    title: 'menu.services',
    href: '/servicos',
  },
  {
    title: 'menu.portfolio',
    href: '/portfolio',
  },
  // {
  //   title: 'menu.blog',
  //   href: '/blog',
  // },
  {
    title: 'menu.contact',
    href: '/contato',
  },
]
